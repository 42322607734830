
import { Vue, Component, Prop, Ref } from "vue-property-decorator"

@Component
export default class UiSlider extends Vue {
  @Prop({ type: Number, default: 200 }) readonly itemWidth!: number
  @Prop({ type: Number, default: 16 }) readonly gap!: number

  @Ref() sliderContainer!: HTMLElement

  offset = 0

  get isAtStart(): boolean {
    return this.offset === 0
  }

  get isAtEnd(): boolean {
    if (this.sliderContainer) {
      const containerWidth = this.sliderContainer.offsetWidth
      const contentWidth = this.sliderContainer.scrollWidth
      return this.offset + containerWidth >= contentWidth
    }
    return false
  }

  scrollLeft(): void {
    this.offset = Math.max(0, this.offset - this.itemWidth - this.gap)
  }

  scrollRight(): void {
    if (this.sliderContainer) {
      const containerWidth = this.sliderContainer.offsetWidth
      const maxOffset = this.sliderContainer.scrollWidth - containerWidth
      this.offset = Math.min(
        maxOffset,
        this.offset + this.itemWidth + this.gap
      )
    }
  }
}
